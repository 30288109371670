import { Fragment, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import NavLink from '@app/stories/NavLink'
import Logo from '@app/stories/Logo'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@app/stories/IconButton'
import Icon from '@app/stories/Icons'
import SignOutButton from '@app/containers/SignOutButton'
import { Toggle } from '@app/hooks/useToggleOpen'
import { useCurrentUser } from '@app/context/CurrentUser'
import Button from '@app/stories/Button'
import Collapse from '@mui/material/Collapse'
import MenuIcon from '@mui/icons-material/Menu'

import useSignInUpModals from '@app/hooks/useSignInUpModals'
import SignInModal from './SignInModal'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'
import { Typography } from '@mui/material'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'

const SideMenu = ({
  plans,
  loginMethods = ['email', 'phone', 'register'],
  isPlan,
  setEnrollmentOpen,
}) => {
  const [open, setOpen] = useState(false)
  const { profileData, authData } = useCurrentUser()

  const { logoSource, planColorPrimary, planPhoneNumber, planSMSNumber } = useWhiteLabelingContext()

  const [supportItemsOpen, setSupportItemsOpen] = useState(false)
  const [myPlansOpen, setMyPlansOpen] = useState(false)
  const [howItWorksOpen, setHowItWorksOpen] = useState(false)

  const { signInOpen, handleOpen } = useSignInUpModals()

  if (authData.roles === null) {
    return 'trying to authenticate...'
  }

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
    setHowItWorksOpen(false)
    setMyPlansOpen(false)
    setSupportItemsOpen(false)
  }

  return (
    <Toggle>
      {() => {
        // Memo the sign in modal
        const signInModal = useMemo(() => {
          return (
            <SignInModal
              open={signInOpen}
              loginMethods={loginMethods}
              handleClose={() => {
                handleOpen('signIn')
                toggleDrawer(false)()
              }}
              callback={() => {
                handleOpen('signIn')
                toggleDrawer(false)()
              }}
            />
          )
        }, [signInOpen, handleOpen])

        return (
          <>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={toggleDrawer(true)}
              size='large'
            >
              <MenuIcon />
            </IconButton>
            <MuiDrawer open={open} onClose={toggleDrawer(false)}>
              {signInModal}
              <Box bgcolor={planColorPrimary}>
                <Button variant='unstyled' to='/' onClick={toggleDrawer(false)}>
                  <Logo size='medium' source={logoSource} />
                </Button>
                <Box
                  style={{
                    position: 'absolute',
                    top: '8px',
                    right: '16px',
                  }}
                >
                  <Button
                    size='small'
                    variant='text'
                    onClick={toggleDrawer(false)}
                    style={{
                      width: '40px',
                      height: '40px',
                      minWidth: '40px',
                      borderRadius: '20px',
                      color: '#fff',
                    }}
                  >
                    <Icon name='exitToApp' />
                  </Button>
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                height='100%'
                width='100%'
                style={{
                  position: 'relative',
                }}
              >
                <List
                  style={{
                    paddingTop: '0',
                  }}
                >
                  <Divider />
                  <ListItem>
                    <NavLink
                      variant='text'
                      onClick={() => setSupportItemsOpen(!supportItemsOpen)}
                      align='left'
                      style={{
                        width: '100%',
                      }}
                    >
                      24/7 Support
                      <Box
                        style={{
                          transform: supportItemsOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s',
                          marginLeft: '2px',
                          marginTop: '-1px',
                        }}
                      >
                        <Icon name='chevronRight' />
                      </Box>
                    </NavLink>
                  </ListItem>
                  <Collapse in={supportItemsOpen} timeout='auto' unmountOnExit>
                    {planPhoneNumber && (
                      <>
                        <Divider />
                        <ListItem>
                          <NavLink
                            className='planPhoneButton'
                            to={`tel:${planPhoneNumber}`}
                            align='left'
                            width='full'
                            external
                            onClick={toggleDrawer(false)}
                          >
                            <Box
                              color={planColorPrimary}
                              display='flex'
                              alignItems='center'
                              gap='8px'
                            >
                              <Icon name='phone' />
                              <Typography variant='body1'>Call</Typography>
                            </Box>
                          </NavLink>
                        </ListItem>
                      </>
                    )}
                    <Divider />
                    <ListItem>
                      <NavLink
                        className='planChatButton'
                        align='left'
                        width='full'
                        onClick={() => {
                          window?.Intercom('show')
                          toggleDrawer(false)()
                        }}
                      >
                        <Box color={planColorPrimary} display='flex' alignItems='center' gap='8px'>
                          <Icon name='chatOutlined' />
                          <Typography variant='body1'>Chat</Typography>
                        </Box>
                      </NavLink>
                    </ListItem>
                    {planSMSNumber && (
                      <>
                        <Divider />
                        <ListItem>
                          <NavLink
                            className='planSMSButton'
                            to={`sms:${planSMSNumber}`}
                            align='left'
                            width='full'
                            external
                            onClick={() => {
                              toggleDrawer(false)()
                            }}
                          >
                            <Box
                              color={planColorPrimary}
                              display='flex'
                              alignItems='center'
                              gap='8px'
                            >
                              <PhoneIphoneIcon />
                              <Typography variant='body1'>SMS</Typography>
                            </Box>
                          </NavLink>
                        </ListItem>
                      </>
                    )}
                  </Collapse>
                  {plans.length > 0 && (
                    <>
                      <Divider />
                      <ListItem>
                        <NavLink
                          variant='text'
                          onClick={() => setMyPlansOpen((prev) => !prev)}
                          align='left'
                          style={{
                            width: '100%',
                          }}
                        >
                          My Plans
                          <Box
                            style={{
                              transform: myPlansOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                              transition: 'transform 0.3s',
                              marginLeft: '2px',
                              marginTop: '-1px',
                            }}
                          >
                            <Icon name='chevronRight' />
                          </Box>
                        </NavLink>
                      </ListItem>
                      <Collapse in={myPlansOpen} timeout='auto' unmountOnExit>
                        {plans.map((plan) => (
                          <Fragment key={plan.id}>
                            <Divider />
                            <ListItem>
                              <NavLink
                                to={`/plans/${plan.slug}`}
                                key={plan.id}
                                align='left'
                                width='full'
                                onClick={toggleDrawer(false)}
                              >
                                {plan.name}
                              </NavLink>
                            </ListItem>
                          </Fragment>
                        ))}
                      </Collapse>
                    </>
                  )}
                  <Divider />
                  <ListItem>
                    <NavLink
                      to={`/plans/mishehealthplan`}
                      align='left'
                      width='full'
                      onClick={toggleDrawer(false)}
                    >
                      Mishe Health Plan
                    </NavLink>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <NavLink
                      to={`/providers`}
                      align='left'
                      width='full'
                      onClick={toggleDrawer(false)}
                    >
                      For Providers
                    </NavLink>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <NavLink
                      to={`/brokers`}
                      align='left'
                      width='full'
                      onClick={toggleDrawer(false)}
                    >
                      For Brokers
                    </NavLink>
                  </ListItem>
                  {profileData.id ? (
                    <>
                      <Divider />
                      <ListItem>
                        <NavLink
                          to='/dashboard'
                          align='left'
                          fullWidth
                          display='standard'
                          family='poppins'
                          onClick={toggleDrawer(false)}
                        >
                          Dashboard
                        </NavLink>
                      </ListItem>
                      <Divider />
                      <ListItem onClick={toggleDrawer(false)}>
                        <SignOutButton color='#000' />
                      </ListItem>
                    </>
                  ) : (
                    <>
                      <Divider />
                      <ListItem>
                        <Box color='#000' width='100%'>
                          <NavLink
                            align='left'
                            fullWidth
                            display='standard'
                            family='poppins'
                            onClick={() => {
                              handleOpen('signIn')
                            }}
                          >
                            Login
                          </NavLink>
                        </Box>
                      </ListItem>
                      <Divider />
                      {loginMethods.includes('register') && (
                        <>
                          {isPlan ? (
                            <ListItem>
                              <NavLink
                                onClick={() => {
                                  setEnrollmentOpen(true)
                                }}
                                display='standard'
                                style={{ borderRadius: '21.125px' }}
                              >
                                Activate Benefits
                              </NavLink>
                            </ListItem>
                          ) : (
                            <ListItem>
                              <Box color='#000' width='100%'>
                                <NavLink
                                  to='/signup'
                                  align='left'
                                  fullWidth
                                  display='standard'
                                  family='poppins'
                                  onClick={toggleDrawer(false)}
                                >
                                  Sign Up
                                </NavLink>
                              </Box>
                            </ListItem>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <Divider />
                </List>
                <List>
                  <Divider />
                  <ListItem>
                    <NavLink
                      to='/privacy-policy'
                      align='left'
                      fullWidth
                      display='standard'
                      onClick={toggleDrawer(false)}
                      family='poppins'
                    >
                      privacy policy
                    </NavLink>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <NavLink
                      to='/terms'
                      align='left'
                      display='standard'
                      fullWidth
                      onClick={toggleDrawer(false)}
                      family='poppins'
                    >
                      terms of use
                    </NavLink>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Box display='flex' justifyContent='space-between' width='100%'>
                      <IconButton
                        target='_blank'
                        href='https://twitter.com/mishehealth'
                        size='large'
                      >
                        <Icon name='twitter' color={planColorPrimary} />
                      </IconButton>
                      <IconButton
                        target='_blank'
                        href='https://www.instagram.com/mishe.health/'
                        size='large'
                      >
                        <Icon name='instagram' color={planColorPrimary} />
                      </IconButton>
                      <IconButton
                        target='_blank'
                        href='https://www.facebook.com/mishe.care'
                        size='large'
                      >
                        <Icon name='facebook' color={planColorPrimary} />
                      </IconButton>
                      <IconButton
                        target='_blank'
                        href='https://www.youtube.com/channel/UCQOg7eSM8FqkMPnzFbC0VdA'
                        size='large'
                      >
                        <Icon name='youtube' color={planColorPrimary} />
                      </IconButton>
                    </Box>
                  </ListItem>
                </List>
              </Box>
            </MuiDrawer>
          </>
        )
      }}
    </Toggle>
  )
}

export default SideMenu
