import { useState, useEffect } from 'react'
import { useCurrentUser } from '@app/context/CurrentUser'
import SelectField from '@app/stories/Select'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import PatientMemberCards from '@app/components/PatientDashboard/PlansTab/memberCards/PatientMemberCards'

import { useEmployerPlan } from '@app/hooks/useEmployerPlan'

const MemberCardPage = () => {
  const { profileData } = useCurrentUser()

  const [plans, setPlans] = useState()
  const [selectedPlanID, setSelectedPlanID] = useState('')

  useEffect(() => {
    let formattedPlans = []
    if (profileData.plansAssociated) {
      formattedPlans = Object.entries(profileData.plansAssociated).map(([id, data]) => ({
        value: id,
        label: data.name,
        ...data,
      }))
    }
    setPlans(formattedPlans)
  }, [profileData.plansAssociated])

  const plan = useEmployerPlan(profileData.id, selectedPlanID)

  if (plans && plan.loaded && plan.empty) {
    return (
      <>
        <SelectField
          variant='standard'
          fullWidth
          size='large'
          label='Select Plan'
          options={plans}
          onChange={(e) => setSelectedPlanID(e.target.value)}
          value={selectedPlanID}
          titleSelect
        />
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
          Plan Details unavailable at this time
        </Box>
      </>
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'scroll',
      }}
    >
      {plans && (
        <SelectField
          variant='standard'
          fullWidth
          size='large'
          label='Select Plan'
          options={plans}
          onChange={(e) => setSelectedPlanID(e.target.value)}
          value={selectedPlanID}
          titleSelect
        />
      )}
      {selectedPlanID && !plan.loaded && (
        <Box display='flex' alignItems='center' justifyContent='center' bgcolor='lightgray'>
          <CircularProgress />
        </Box>
      )}
      {plan && plan.loaded && (
        <>
          <PatientMemberCards
            profile={profileData}
            uid={profileData.id}
            plan={plan}
            planID={selectedPlanID}
          />
        </>
      )}
    </Box>
  )
}

export default MemberCardPage
