import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import Notification from '@app/components/NotificationText'
import Typography from '@app/stories/Typography'
import CheckoutStatus from '@app/stories/CheckoutStatus'
import Calendar from '@app/components/Calendar'
import Button from '@app/stories/Button'
import { useQuickCheckoutOrders } from '@app/context/QuickCheckoutEmrOrders'
import parseQueryString from '@app/libs/parseQueryStrings'
import { collection } from '@app/firebase/firestore'

import DateRangeIcon from '@mui/icons-material/DateRange'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { DatePicker } from '@mui/x-date-pickers'

import useIdentificationCards from '@app/hooks/useIdentificationCards'
import { useCurrentUser } from '@app/context/CurrentUser'

import styled from '@mui/system/styled'

import TextField from '@mui/material/TextField'

const EmrOrdersCheckoutForm = ({ orderType, orderId, patientInfo }) => {
  const { search } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const { disabled, setDisabled } = useQuickCheckoutOrders()
  const [dates, setDates] = useState([])
  const [error, setError] = useState('')
  const [pendingClientSecret, setPendingClientSecret] = useState('')
  const [successfulPaymentIntent, setSuccessfulPaymentIntent] = useState('')
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [utms, setUtms] = useState({})

  const { profileData } = useCurrentUser()

  const [validIdInfo, setValidIdInfo] = useState(false)
  const [idNumber, setIdNumber] = useState(null)
  const [idExpiry, setIdExpiry] = useState(null)
  const [idImage, setIdImage] = useState(null)
  const [preview, setPreview] = useState(null)

  const [idErrorMessage, setIdErrorMessage] = useState(null)

  const { checkProfileForID, checkForIdentificationInfo, updateProfileWithIdentificationInfo } =
    useIdentificationCards()

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  useEffect(() => {
    if (profileData) {
      setValidIdInfo(checkProfileForID({ profile: profileData, setIdErrorMessage }))
    }
  }, [profileData])

  useEffect(() => {
    if (!idImage) {
      setPreview(null)
      return
    }
    setPreview(URL.createObjectURL(idImage))
  }, [idImage])

  useEffect(() => {
    const { ref, payment_intent, payment_intent_client_secret, ...utmsQuery } =
      parseQueryString(search)
    if (ref) {
      localStorage.setItem('refvalue', ref)
    }
    setUtms(utmsQuery)
    setPendingClientSecret(payment_intent_client_secret)
  }, [search])

  useEffect(() => {
    async function checkPaymentStatus() {
      const result = await stripe.retrievePaymentIntent(pendingClientSecret)
      const { paymentIntent } = result
      console.log(paymentIntent)
      if (result.error) {
        setPaymentStatus('error')
        window.Intercom('trackEvent', 'Checkout Error', {
          typeOfCheckout: 'Order',
          error: result.error || 'Unknown Stripe Error',
          ...utms,
        })
        return undefined
      }

      if (!paymentIntent) {
        setPaymentStatus('error')
        return undefined
      }

      if (paymentIntent.error) {
        console.log(paymentIntent.error)
        return undefined
      }
      if (paymentIntent.status === 'succeeded') {
        console.log('Payment Succeeded!')
      }
      if (paymentIntent.status === 'requires_payment_method') {
        console.log('Payment Failed!')
      }
      if (paymentIntent.status === 'requires_action') {
        console.log('Payment Requires Action!')
      }
      if (paymentIntent.status === 'processing') {
        console.log('Payment Processing!')
      }
      window.Intercom('trackEvent', `Checkout ${paymentIntent.status}`, {
        typeOfCheckout: 'Order',
        paymentIntent: paymentIntent.id,
        paymentStatus: paymentIntent.status,
        error: paymentIntent.error || 'None',
        ...utms,
      })
      setSuccessfulPaymentIntent(paymentIntent.id)
      setPaymentStatus(`${paymentIntent.error ? 'error' : paymentIntent.status}`)
    }
    if (!stripe || !pendingClientSecret) {
      return undefined
    }

    checkPaymentStatus()
  }, [pendingClientSecret, stripe, utms])

  useEffect(() => {
    if (paymentStatus === 'error' || paymentStatus === 'requires_payment_method') {
      setPendingClientSecret(null)
    }
  }, [paymentStatus])

  const handleIdInfo = useCallback(async () => {
    setIdErrorMessage(null)

    console.log('  -- uploading id info')
    console.log('=======================IdImage')
    console.log(idImage)

    if (
      !checkForIdentificationInfo({
        idNumber,
        idExpiry,
        idImageFile: idImage,
        errorCallback: setIdErrorMessage,
      })
    ) {
      console.log('  ** id info not valid')
      setDisabled(false)
      return
    }

    const idUploadLink = await updateProfileWithIdentificationInfo({
      id: profileData.id,
      idNumber,
      idExpiry,
      idFile: idImage,
    })

    console.log('  -- id upload result')
    console.log(idUploadLink ? 'success' : 'failure')

    if (!idUploadLink) {
      console.log('  ** error uploading id info')
      setIdErrorMessage(
        'Oh uh, looks like we ran into an issue uploading your ID info.\nPlease contact support if this issue persists.',
      )
      setDisabled(false)
      return
    }

    return idUploadLink
  }, [idNumber, idExpiry, idImage, profileData.id])

  const errorHandler = () => {
    if (orderType !== 'medication' && dates.length < 3) {
      setError('Please select 3 preferred dates.')
      setDisabled(false)
      return false
    }
    if (!validIdInfo && (!idImage || !idExpiry || !idNumber)) {
      setError('Please upload your ID.')
      setDisabled(false)
      return false
    }

    return true
  }

  const checkoutSequence = async (event) => {
    event.preventDefault()
    setDisabled(true)
    setError(null)

    if (!errorHandler()) {
      return
    }

    if (!validIdInfo) {
      console.log('  ** id info not valid - updating')
      console.log('    -- user ID')
      console.log(profileData.id)
      const handleIdInfoResult = await handleIdInfo()
      console.log('    -- handleIdInfoResult')
      console.log(handleIdInfoResult)
    }

    if (orderType === 'medication') {
      const orderRef = collection('emrOrders').doc(orderId)
      const orderDoc = await orderRef.get()

      const { shippingInfo } = patientInfo

      if (!orderDoc.exists) {
        return
      }

      if (shippingInfo) {
        const orderData = {
          patient: {
            shippingAddress: shippingInfo.address,
            shippingAddress2: shippingInfo.address2,
            city: shippingInfo.city,
            state: shippingInfo.state,
            zip: shippingInfo.zip,
          },
        }

        // update the emr order to processing
        await orderRef
          .set(orderData, { merge: true })
          .then(() => {
            console.log('Order updated')
            // navigate to dashboard after 5 seconds
            setTimeout(() => {
              window.location.href = '/dashboard'
            }, 5000)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    // Check if stripe and elements are loaded
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log('stripe or elements null')
      return
    }

    // Trigger payment on stripe
    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.href}`,
      },
      redirect: 'if_required',
    })

    console.log(result)

    if (result?.error) {
      window.Intercom('trackEvent', 'Checkout Error', {
        typeOfCheckout: 'Order',
        errorType: result.error.type,
        errorCode: result.error.code,
        errorDeclineReason: result.error.decline_code || 'None',
        ...utms,
      })
      setDisabled(false)
      setPaymentStatus('error')
      return
    }

    window.Intercom('trackEvent', `Checkout ${result.paymentIntent.status}`, {
      typeOfCheckout: 'Order',
      ...result,
    })

    if (
      result.paymentIntent.status === 'requires_payment_method' ||
      result.paymentIntent.status === 'requires_action'
    ) {
      setDisabled(false)
    } else {
      setSuccessfulPaymentIntent(result.paymentIntent.id)
    }
    setPaymentStatus(result.paymentIntent.status)
  }

  return (
    <Box id='checkoutContainer'>
      {!pendingClientSecret && paymentStatus !== 'succeeded' && paymentStatus !== 'processing' ? (
        <form title='customer-info-form' onSubmit={checkoutSequence}>
          <Grid
            container
            spacing={1}
            sx={{
              border: '1px solid rgba(0,0,0,0.1)',
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
              borderRadius: '5px',
              padding: '20px',
              margin: '20px 0',
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant='h4' gutterBottom>
                Enter your payment information.
              </Typography>
            </Grid>
            {orderType !== 'medication' && (
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant='h6' gutterBottom>
                  Select 3 preferred times & dates.
                </Typography>
                <Calendar setDates={setDates} dates={dates} />
              </Grid>
            )}
            {!validIdInfo && (
              <>
                <Divider
                  style={{
                    width: '100%',
                    marginTop: '20px',
                  }}
                />
                <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                  <Typography variant='h4' align='center'>
                    Government ID
                  </Typography>
                </Grid>
                {idErrorMessage && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      variant='body1'
                      style={{
                        color: 'red',
                      }}
                    >
                      {idErrorMessage}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <Typography variant='caption'>
                      Enter your ID/Drivers License Number as it appears.
                    </Typography>
                  </Box>
                  <TextField
                    id='dirx-id-number'
                    value={idNumber}
                    onChange={(e) => {
                      setIdNumber(e.target.value)
                    }}
                    label='ID/Drivers License Number'
                    variant='outlined'
                    fullWidth
                    disabled={disabled && true}
                    sx={{
                      marginTop: '0px',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <Typography variant='caption'>
                      ID must expire more than 7 days from today.
                    </Typography>
                  </Box>
                  <DatePicker
                    id='id-expiry'
                    label='ID/Drivers License Expiration Date'
                    value={idExpiry}
                    onChange={setIdExpiry}
                    inputVariant='outlined'
                    bgcolor='background.paper'
                    format='MM-dd-yyyy'
                    keyboardIcon={<DateRangeIcon />}
                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    slotProps={{
                      textField: { required: true, fullWidth: true },
                    }}
                    disabled={disabled && true}
                    minDate={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '10px' }} mb={2}>
                  <Box
                    mt={1}
                    width='100%'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      flexDirection='column'
                      width='100%'
                    >
                      <Button
                        component='label'
                        variant='outlined'
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        disabled={disabled && true}
                        size='large'
                        sx={{
                          minWidth: '155px',
                        }}
                      >
                        <Typography variant='body1' whiteSpace='nowrap'>
                          Upload ID
                        </Typography>
                        <VisuallyHiddenInput
                          id='dirx-id-file'
                          type='file'
                          accept='image/*'
                          disabled={disabled && true}
                          onChange={(e) => {
                            setIdImage(e.target.files[0])
                            const objectUrl = URL.createObjectURL(e.target.files[0])
                            setPreview(objectUrl)
                          }}
                        />
                      </Button>
                      <Button
                        component='label'
                        variant='outlined'
                        tabIndex={-1}
                        startIcon={<DeleteForeverIcon />}
                        disabled={disabled && true}
                        size='large'
                        sx={{
                          marginTop: '10px',
                          minWidth: '155px',
                        }}
                        onClick={() => {
                          setIdImage(null)
                        }}
                      >
                        <Typography variant='body1' whiteSpace='nowrap'>
                          Clear
                        </Typography>
                      </Button>
                    </Box>
                    <Box
                      width='100%'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      padding={2}
                    >
                      {preview ? (
                        <img
                          id='id_preview'
                          src={preview}
                          alt='ID Preview'
                          style={{
                            width: '100%',
                            maxWidth: '400px',
                            height: 'auto',
                          }}
                        />
                      ) : (
                        <Typography variant='body1'>
                          Please upload a clear picture of the front of your ID.
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12}>
              <PaymentElement id='payment-element' />
              {error && (
                <Notification
                  message={error}
                  style={{
                    backgroundColor: 'rgba(254, 35, 32, 0.19)',
                    padding: '10px',
                    borderRadius: '5px',
                    color: '#000',
                  }}
                />
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box alignItems='center' mt={2}>
                  <Button
                    fullWidth
                    type='submit'
                    disabled={disabled}
                    style={{
                      width: '100%',
                    }}
                  >
                    Confirm Payment
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {paymentStatus === 'requires_payment_method' ||
                  (paymentStatus === 'requires_action' && (
                    <Notification
                      message='Payment Failed. Please try again.'
                      style={{
                        backgroundColor: 'rgba(254, 35, 32, 0.19)',
                        padding: '10px',
                        borderRadius: '5px',
                        color: '#000',
                      }}
                    />
                  ))}
                {paymentStatus === 'error' && (
                  <Notification
                    message='There was an error with your payment. Please try again.'
                    style={{
                      backgroundColor: 'rgba(254, 35, 32, 0.19)',
                      padding: '10px',
                      borderRadius: '5px',
                      color: '#000',
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      ) : (
        <CheckoutStatus status={paymentStatus} orderId={successfulPaymentIntent} />
      )}
    </Box>
  )
}

EmrOrdersCheckoutForm.propTypes = {
  orderType: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired,
}

export default EmrOrdersCheckoutForm
