import { useState, useEffect, useCallback } from 'react'
import { collection } from '@app/firebase/firestore'
import { requestClaimsShare, acceptClaimsShare, getSharedClaims } from '@app/firebase/functions'

/**
 * Hook for fetching and handling data related to a member plan in a `networks` collection and its
 * subcollections.
 *
 * @param {string} memberId - The doc ID of the member whose data is being fetched.
 * @param {string} planId - The doc ID of the plan to retrieve data from.
 * @returns {object} Various plan- and member-related data and actions.
 */
export const useEmployerPlan = (memberId, planId) => {
  const [planData, setPlanData] = useState(null)
  const [familyVouchers, setFamilyVouchers] = useState(null)
  const [memberData, setMemberData] = useState({})
  const [familyData, setFamilyData] = useState(null) // <--- We store any "family" doc data here
  const [loaded, setLoaded] = useState(false)
  const [empty, setEmpty] = useState(false)
  const [error, setError] = useState(null)

  /** Helper function to sum `amountPaid` from `vouchersSpentOn` across any array of members. */
  const getAllFamilyMembersVouchersTotal = (members) => {
    if (!members) return 0
    return members.reduce((total, member) => {
      const spentOn = member?.vouchersSpentOn || []
      const memberTotal = spentOn.reduce((sum, voucher) => sum + (voucher?.amountPaid || 0), 0)
      return total + memberTotal
    }, 0)
  }

  /**
   * Fetches all necessary data:
   *
   * - The main plan doc (`planData`)
   * - The current member doc (`memberData`)
   * - Optionally, family members (in `familyVouchers`) if this member is primary
   * - Optionally, a family doc (`familyData`), if there's a `familyPlanId` and "sharedPool"
   */
  const fetchData = useCallback(async () => {
    setLoaded(false)
    setEmpty(false)
    setError(null)

    try {
      // 1) Fetch the plan members where memberId == <memberId>.
      let planMembersSnapshot = await collection('networks')
        .doc(planId)
        .collection('members')
        .where('memberId', '==', memberId)
        .get()

      if (!planMembersSnapshot || planMembersSnapshot.empty) {
        setEmpty(true)
        setLoaded(true)
        return
      }

      // 2) Determine the "current" member doc.
      const currentMemberDoc = planMembersSnapshot.docs.find((doc) => doc.id === memberId)
      const currentMemberData = currentMemberDoc
        ? currentMemberDoc.data()
        : planMembersSnapshot.docs[0].data()

      if (!currentMemberData) {
        console.error('Member data not found.')
        setEmpty(true)
        setLoaded(true)
        return
      }

      // 3) If the current member is type=1 (primary) AND has a `familyPlanId`, fetch their "family" members
      if (currentMemberData.type === 1 && currentMemberData.familyPlanId) {
        try {
          planMembersSnapshot = await collection('networks')
            .doc(planId)
            .collection('members')
            .where('familyPlanId', '==', currentMemberData.familyPlanId)
            .get()
        } catch (fetchFamilyErr) {
          console.error('Failed to fetch family members:', fetchFamilyErr)
          // Retain the original planMembersSnapshot if family fetch fails
        }
      }

      // 4) Build an array of vouchers for each relevant doc but keep it as close looking to plan member doc format
      const vouchersPerPerson = planMembersSnapshot.docs.map((doc) => ({
        memberId: doc.id,
        shareStatus: doc.data()?.shareStatus || 'not-shared',
        ...doc.data(),
      }))

      // 5) Fetch the main plan doc
      const planRef = await collection('networks').doc(planId).get()
      const fetchedPlanData = planRef.data()
      if (!fetchedPlanData) {
        throw new Error('Plan data not found.')
      }

      // 6) If designType.spend === 'down', we optionally fetch the family's doc
      let fetchedFamilyData = null
      const isSpendDown = fetchedPlanData?.planDesign?.designType?.spend === 'down'
      if (isSpendDown && currentMemberData.familyPlanId) {
        const familyDocRef = await collection('networks')
          .doc(planId)
          .collection('families')
          .doc(currentMemberData.familyPlanId)
          .get()

        if (familyDocRef.exists) {
          fetchedFamilyData = familyDocRef.data()
        }
      }

      // 7) Update states
      setPlanData(fetchedPlanData)
      setMemberData(currentMemberData)
      setFamilyData(fetchedFamilyData)
      setFamilyVouchers(vouchersPerPerson)
      setEmpty(false)
      setLoaded(true)
    } catch (err) {
      console.error('Failed to fetch plan data:', err)
      setError(err)
      setEmpty(true)
      setLoaded(true)
    }
  }, [planId, memberId])

  /** UseEffect for data initialization whenever memberId/planId changes. */
  useEffect(() => {
    if (!planId) return

    let isMounted = true

    fetchData().then(() => {
      if (!isMounted) return
    })

    return () => {
      isMounted = false
    }
  }, [planId, fetchData])

  /**
   * FamilyBreakdown() - returns an array of "breakdown" objects, each representing how much a
   * non-primary family member spent.
   */
  const familyBreakdown = useCallback(() => {
    if (memberData.type !== 1 || !familyVouchers) return []
    const breakdown = []

    familyVouchers.forEach((famMember) => {
      if (famMember.type !== 1) {
        const vouchers = famMember.vouchersSpentOn?.map((voucher) => ({
          name: famMember.name,
          type: famMember.type,
          voucher,
        }))
        if (vouchers) breakdown.push(...vouchers)
      }
    })
    return breakdown
  }, [familyVouchers, memberData.type])

  /** TotalFamilySpend() - sum of amounts paid from all family members' vouchers */
  const totalFamilySpend = useCallback(() => {
    return getAllFamilyMembersVouchersTotal(familyVouchers)
  }, [familyVouchers])

  /** Deductible() - compute how much of the family deductible has been spent, and how much remains. */
  const deductible = useCallback(() => {
    const totalPaid = getAllFamilyMembersVouchersTotal(familyVouchers)
    const totalDeductible = planData?.planDesign?.deductible?.family || 0
    const remaining = Math.max(0, totalDeductible - totalPaid)

    return {
      remaining,
      total: totalDeductible,
      spent: totalPaid,
    }
  }, [familyVouchers, planData])

  /**
   * Oop() - compute how much of the family's out-of-pocket (OOP) max has been spent, and how much
   * remains.
   */
  const oop = useCallback(() => {
    const totalPaid = getAllFamilyMembersVouchersTotal(familyVouchers)
    const totalOOP = planData?.planDesign?.outOfPocketMax?.family || 0
    const remaining = Math.max(0, totalOOP - totalPaid)

    return { remaining, total: totalOOP, spent: totalPaid }
  }, [familyVouchers, planData])

  /**
   * Spend() - for "spend down" plans, shows how much has been spent vs. the maximum. If the plan is
   * not "spend down," we just return the sum from vouchers as a fallback.
   *
   * We do NOT store spendRules in state – we compute them from familyData or memberData each time
   * `spend()` is called.
   */
  const spend = useCallback(() => {
    const isSpendDown = planData?.planDesign?.designType?.spend === 'down'
    if (!isSpendDown) {
      // Fallback: if no "spend down," we just total the family’s vouchers
      const totalSpent = getAllFamilyMembersVouchersTotal(familyVouchers)
      return { remaining: 0, max: 0, spent: totalSpent }
    }

    // If we do have a "spend down" design, check if there's a family shared pool
    const hasSharedPool = familyData?.spendRules?.sharedPool
    let maxSpend = 0
    let spent = 0

    if (hasSharedPool) {
      const { spendRules: familySpendRules } = familyData
      maxSpend = familySpendRules?.maxSpend || familySpendRules?.limit || 0
      spent = familySpendRules?.spent || 0
    } else {
      // Otherwise, use the member’s own spendRules
      const memberSpendRules = memberData?.spendRules
      maxSpend = memberSpendRules?.maxSpend || memberSpendRules?.limit || 0
      spent = memberSpendRules?.spent || 0
    }

    const remaining = Math.max(0, maxSpend - spent)

    return {
      remaining,
      max: maxSpend,
      spent,
    }
  }, [planData, familyData, memberData, familyVouchers])

  /** HandleRequestClaimsShare() - initiates a share request for the claims of another member. */
  const handleRequestClaimsShare = useCallback(
    async (targetMemberId) => {
      try {
        const response = await requestClaimsShare({
          planId,
          requestedMemberId: targetMemberId,
        })

        // Update local state so UI knows share status changed
        setFamilyVouchers((prev) =>
          prev?.map((member) =>
            member.memberId === targetMemberId
              ? { ...member, shareStatus: response?.shareStatus }
              : member,
          ),
        )
        return response
      } catch (err) {
        console.error('Error requesting claims share:', err)
        setError(err)
        throw err
      }
    },
    [planId],
  )

  /** HandleGrantClaimsShare() - grants a share request for this member’s claims. */
  const handleGrantClaimsShare = useCallback(async () => {
    try {
      const response = await acceptClaimsShare({ planId })
      // Update local state
      setFamilyVouchers((prev) =>
        prev?.map((member) =>
          member.memberId === memberId ? { ...member, shareStatus: response?.shareStatus } : member,
        ),
      )
      return response
    } catch (err) {
      console.error('Error granting claims share:', err)
      setError(err)
      throw err
    }
  }, [planId, memberId])

  /** GetMemberSharedClaims() - fetches shared claims for a specific member. */
  const getMemberSharedClaims = useCallback(
    async (targetMemberId) => {
      try {
        const response = await getSharedClaims({
          planId,
          requestedMemberId: targetMemberId,
        })
        return response
      } catch (err) {
        console.error('Failed to fetch shared claims:', err)
        setError(err)
        return { status: 'error', error: 'Failed to fetch shared claims.' }
      }
    },
    [planId],
  )

  /** GetCurrentMemberShareStatus() - returns the sharing status of the current member. */
  const getCurrentMemberShareStatus = useCallback(() => {
    return memberData?.shareStatus || 'not-shared'
  }, [memberData])

  return {
    // statuses
    loaded,
    empty,
    error,

    // Plan and member raw data
    planData,
    memberData,
    familyVouchers,

    // Derived values
    familyBreakdown,
    totalFamilySpend,
    deductible,
    oop,
    spend,

    // Actions
    handleRequestClaimsShare,
    handleGrantClaimsShare,
    getMemberSharedClaims,
    getCurrentMemberShareStatus,
  }
}
