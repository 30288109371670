import firebase from 'firebase/compat/app'

export const convertFirestoreToDate = (firestoreTimestamp) => {
  if (!firestoreTimestamp || !firestoreTimestamp.toDate) {
    return false
  }

  // Convert Firestore.timestamp to javascript.Date in UTC
  const date = firestoreTimestamp.toDate()

  // Return the UTC date with only year, month, and day like 00:00:00 UTC
  return date
}

// todo im not sure the purpose of this method. i dont think its correct.
// when you manually adjust the time using getTimezoneOffset() your  shifting the dates time and that can
// cause it to fall on the next day if its close to midnight
// so this offset can affect the date. why do we need it for birthday input for example. the user inputs their birthday
// we dont need to adjust their birthday to match a timezone
const convertOffset = (date) => {
  const tzoffset = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() + tzoffset)
}

export const convertToJSDate = (date) => {
  if (!date) {
    return null
  }

  // Handle
  if (date instanceof firebase.firestore.Timestamp) {
    return date.toDate()
  }

  if (date?._seconds || date?._nanoseconds) {
    // convert back to firebase timestamp
    return new firebase.firestore.Timestamp(date._seconds, date._nanoseconds).toDate()
  }

  if (date instanceof Date) {
    return date
  }

  const BIRTHDAY_REGEX_DMY = /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/
  const BIRTHDAY_REGEX_UNIVERSAL = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/

  if (BIRTHDAY_REGEX_DMY.test(date)) {
    const [month, day, year] = date.split('-')
    return new Date(`${year}-${month}-${day}T00:10:00`)
  }

  if (BIRTHDAY_REGEX_UNIVERSAL.test(date)) {
    return new Date(`${date}T00:10:00`)
  }

  return ''
}

export const convertDateToUtcMidnight = (date) => {
  if (!date) {
    return null
  }

  const jsDate = convertToJSDate(date)

  if (!jsDate) {
    return null
  }

  // convert date to UTC midnight and return Date object
  const utcMidnight = new Date(jsDate)
  utcMidnight.setUTCHours(0, 0, 0, 0)
  return utcMidnight
}

export const formatDateForDatePickerUI = (date) => {
  if (!date) {
    return ''
  }

  return convertOffset(convertToJSDate(date))
}

export const formatDateForUI = (date, displayTime = false) => {
  if (!date) {
    return ''
  }

  if (typeof date === 'string') {
    const DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})$|^(\d{2})\/(\d{2})\/(\d{4})$/
    const match = date.match(DATE_REGEX)
    if (match) {
      return match[1] ? `${match[2]}/${match[3]}/${match[1]}` : date
    }
  }

  const jsDate = convertDateToUtcMidnight(date)

  if (!jsDate) {
    return ''
  }

  const offsetDate = convertOffset(jsDate)

  if (!offsetDate) {
    return ''
  }

  const dateFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }

  if (displayTime) {
    dateFormat.hour = '2-digit'
    dateFormat.minute = '2-digit'
  }

  // Convert timestamp back to Date object
  return offsetDate.toLocaleDateString('en-US', dateFormat)
}

export const formatDateAndTimeForUI = (date, displayTime = false) => {
  if (!date) {
    return ''
  }

  const jsDate = convertToJSDate(date)

  if (!jsDate) {
    return ''
  }

  const dateFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }

  if (displayTime) {
    dateFormat.hour = '2-digit'
    dateFormat.minute = '2-digit'
  }

  // Convert timestamp back to Date object
  return jsDate.toLocaleDateString('en-US', dateFormat)
}

export const formatDOBForIntercom = (dob) => {
  // need to return birthday as a unix
  if (!dob) {
    return null
  }

  const jsDate = convertToJSDate(dob)

  return Math.floor(jsDate.getTime() / 1000)
}

export const formatPhoneNumberForAuth = (number) => {
  // convert phone into e.164 standard format assuming all are US removing any special characters

  // Remove any non-numeric characters except +
  const normalizedPhone = number.replace(/[^0-9+]/g, '')

  // check if number is already formatted for auth
  if (normalizedPhone.match(/^\+1\d{10}$/)) {
    return normalizedPhone
  }

  // check if number is exactly 10 digits and format for auth
  if (normalizedPhone.match(/^\d{10}$/)) {
    return `+1${normalizedPhone}`
  }

  return null
}

export const formatPhoneNumberForSMS = (number) => {
  // convert phone into e.164 standard format assuming all are US removing any special characters
  // check if number is already formatted for auth but remove any non-numeric characters except +
  if (number.match(/^\+1\d{10}$/)) {
    return `+${number.replace(/\D/g, '')}`
  }
  return `+1${number.replace(/\D/g, '')}`
}

/**
 * Formats a phone number in this format (123) 456-7890. Used for saving to a user's profile or plan
 * member record. Can also be used for displaying numbers nicely on the UI.
 *
 * @param {string} number - The phone number to format.
 * @returns {string} - The formatted phone number.
 */
export const formatPhoneNumberForProfile = (number) => {
  const cleaned = `${number}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`
  }
  return number
}

export const formatPhoneNumberForDiRx = (number) => {
  const cleaned = `${number}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `${match[2]}-${match[3]}-${match[4]}`
  }
  return null
}

export const moneyFormatter = (amount) => {
  if (typeof amount !== 'number') {
    return amount
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)
}

export const retrieveFiretableImageURL = (imageArr) => {
  if (!imageArr || !Array.isArray(imageArr)) {
    return null
  }

  if (imageArr.length === 0) {
    return null
  }

  return imageArr[0]?.downloadURL
}

export const formatMarkdown = (markdownText) => {
  return markdownText?.replaceAll('&#x20;', ' ') || markdownText
}

export const formatCurrency = (value) => {
  const numberValue = typeof value === 'string' ? parseFloat(value) : value
  if (typeof numberValue !== 'number' || isNaN(numberValue)) {
    return false
  }
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numberValue)
}
