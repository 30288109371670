import { memo, useRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@app/stories/Typography'
import {
  getFullName,
  getAddress,
  getBirthday,
  getGender,
  getPhone,
} from '@app/utils/userFormatterUtil'
import Icon from '@app/stories/Icons'

/** Headers for patient information, including label, icon, and formatter function. */
const PATIENT_INFO_HEADERS = [
  { label: 'Name', icon: 'star', formatter: getFullName },
  { label: 'Address', icon: 'location', formatter: getAddress },
  { label: 'Birthday', icon: 'cake', formatter: getBirthday },
  { label: 'Gender', icon: 'wc', formatter: getGender },
  { label: 'Phone', icon: 'phone', formatter: getPhone },
]

/**
 * Component to render a single row of patient information.
 *
 * @param {Object} props - Component props.
 * @param {string} props.label - The label for the information (e.g., "Name").
 * @param {string} props.body - The value of the information (e.g., "John Doe").
 * @param {string} [props.icon] - The name of the icon to display.
 * @returns {JSX.Element} The rendered InfoRow component.
 */
const InfoRow = ({ label = '', body, icon }) => (
  <Box display='flex' alignItems='center' my={2}>
    {icon && <Icon name={icon} fontSize='small' color='primary' />}
    <Box fontWeight={500} ml={2}>
      <Typography
        variant='body1'
        style={{
          textTransform: 'capitalize',
        }}
      >
        {label}
      </Typography>
    </Box>
    <Box ml={1} mr={2}>
      <Typography
        variant='body1'
        style={{
          textTransform: 'capitalize',
        }}
      >
        {body}
      </Typography>
    </Box>
  </Box>
)

/**
 * Component to display patient information based on the provided voucher data.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.voucher - The voucher object containing patient details.
 * @returns {JSX.Element} The rendered PatientInfo component.
 */
const PatientInfo = ({ voucher }) => (
  <Box>
    <Typography variant='h4' mt={2} mb={1}>
      Patient Info
    </Typography>
    <Box>
      {PATIENT_INFO_HEADERS.map((field, index) => (
        <InfoRow
          key={index}
          label={field.label}
          body={field.formatter(voucher)}
          icon={field.icon}
        />
      ))}
    </Box>
  </Box>
)

export default memo(PatientInfo)
