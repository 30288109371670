import { Route, Routes } from 'react-router-dom'
import PatientVouchers from '@app/containers/PatientVouchers'
import PatientData from '@app/containers/PatientData'
import ProviderVouchers from '@app/components/ProviderVouchers'
import MyInfo from '@app/components/MyInfo'
import DashboardTabs from '@app/components/DashboardTabs'
import Dashboard from '@app/pages/DashboardPage'
import ProviderOrders from '@app/components/ProviderOrders'
import OrdersDashboardContent from '@app/components/OrdersDashboardContent'
import ReportsContent from '@app/components/ReportsContent'
import PlansPatientDashboardContent from '@app/components/PlansPatientDashboardContent'
import MemberCardPage from '@app/components/MemberCardPage'
import PlansDashboardContent from '@app/components/PlansDashboardContent'
import VoucherDetailsContainer from '@app/containers/VoucherDetailsContainer'
import ClaimsActivity from '@app/components/PlanAdminDashboard/ClaimsActivity'
import MemberCensus from '@app/components/PlanAdminDashboard/MemberCensus'
import ProviderDirectory from '@app/components/PlanAdminDashboard/ProviderDirectory'
import FeeSchedule from '@app/components/PlanAdminDashboard/FeeSchedule'
import PlanEFTEnrollment from '@app/components/PlanAdminDashboard/EFTEnrollment'
import PlanDesign from '@app/components/PlanAdminDashboard/PlanDesign'

import { useCurrentUser } from '@app/context/CurrentUser'
import PageNotFound from '@app/pages/PageNotFound'
import LoadingBar from '@app/stories/RandomLoadingBar'

import ClinicEFTEnrollment from '@app/components/Payouts/EFTEnrollment'
import Payouts from '@app/components/Payouts/ClinicPayouts'

import {
  PATIENT_DASHBOARD_TABS,
  PROVIDER_DASHBOARD_TABS,
  PLANADMIN_DASHBOARD_TABS,
} from '@app/libs/constant'
import PlanAdminInvoices from '@app/components/Invoice/PlanAdminInvoices'

const PatientDashboard = () => (
  <Dashboard>
    <Routes>
      <Route path='/' element={<DashboardTabs tabs={PATIENT_DASHBOARD_TABS} />}>
        <Route path='vouchers' element={<PatientVouchers />} />
        <Route path='patientdata' element={<PatientData />} />
        <Route path='vouchers/:voucherId' element={<VoucherDetailsContainer />} />
        <Route path='vouchers/:voucherId/:subvoucherId' element={<VoucherDetailsContainer />} />
        <Route path='info' element={<MyInfo />} />
        <Route path='orders' element={<OrdersDashboardContent />} />
        <Route path='reports' element={<ReportsContent />} />
        <Route path='plans' element={<PlansPatientDashboardContent />} />
        <Route path='membercard' element={<MemberCardPage />} />
        <Route index element={<PatientVouchers />} />
        <Route path='*' element={<PageNotFound />} />
      </Route>
    </Routes>
  </Dashboard>
)

const ProviderDashboard = () => (
  <Dashboard>
    <Routes>
      <Route path='/' element={<DashboardTabs tabs={PROVIDER_DASHBOARD_TABS} />}>
        <Route path='vouchers' element={<ProviderVouchers />} />
        <Route path='vouchers/:voucherId' element={<VoucherDetailsContainer />} />
        <Route path='vouchers/:voucherId/:subvoucherId' element={<VoucherDetailsContainer />} />
        <Route path='info' element={<MyInfo />} />
        <Route path='orders' element={<ProviderOrders />} />
        <Route path='reports' element={<ReportsContent />} />
        <Route path='plans' element={<PlansDashboardContent />} />
        <Route path='enrollment' element={<ClinicEFTEnrollment />} />
        <Route path='payouts' element={<Payouts />} />
        <Route index element={<ProviderVouchers />} />
        <Route path='*' element={<PageNotFound />} />
      </Route>
    </Routes>
  </Dashboard>
)

const PlanAdminDashboard = () => (
  <Dashboard>
    <Routes>
      <Route path='/' element={<DashboardTabs tabs={PLANADMIN_DASHBOARD_TABS} />}>
        <Route path='claims' element={<PlanAdminInvoices />} />
        <Route path='activity' element={<ClaimsActivity />} />
        <Route path='census' element={<MemberCensus />} />
        <Route path='directory' element={<ProviderDirectory />} />
        <Route path='fee-schedule' element={<FeeSchedule />} />
        <Route path='enrollment' element={<PlanEFTEnrollment />} />
        <Route path='plan-design' element={<PlanDesign />} />
        <Route index element={<PlanAdminInvoices />} />
        <Route path='*' element={<PageNotFound />} />
      </Route>
    </Routes>
  </Dashboard>
)

const DashboardRoutes = () => {
  const { authData } = useCurrentUser()
  if (!authData.roles) {
    return <LoadingBar />
  }

  if (authData.roles.includes('PATIENT')) {
    return <PatientDashboard />
  }

  if (authData.roles.includes('PROVIDER')) {
    return <ProviderDashboard />
  }

  if (authData.roles.includes('PLAN_ADMIN')) {
    return <PlanAdminDashboard />
  }
}

export default DashboardRoutes
