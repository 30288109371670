import PropTypes from 'prop-types'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'
import GeneralModal from '@app/containers/GeneralModal'
import { Box, Button } from '@mui/material'

/**
 * Renders the Explanation of Benefits (EOB) button and modal.
 *
 * @param {Object} props - Component props.
 * @param {Object[]} props.attachments - Array of attachments objects.
 * @param {string} [props.label] - Label for the button.
 * @returns {JSX.Element} - The rendered EOB button and modal.
 */
const ClaimAttachmentsButton = ({ attachments = [], label }) => {
  console.log('attachments', attachments)

  if (!attachments || attachments.length === 0) {
    return (
      <Button
        variant='outlined'
        size='small'
        style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
        disabled
      >
        <Icon name='assignmentOutlined' />
        <Typography variant='body2' style={{ marginLeft: '8px' }}>
          {label}
        </Typography>
      </Button>
    )
  }

  return (
    <GeneralModal
      id='Attachments'
      headerText='Attachments'
      maxWidth={'lg'}
      customButton={
        <Box display='flex' alignItems='center'>
          <Button
            variant='outlined'
            size='small'
            style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
          >
            <Icon name='attachFile' />
            <Typography variant='body2' style={{ marginLeft: '8px' }}>
              {label}
            </Typography>
          </Button>
        </Box>
      }
    >
      <Box display='flex' justifyContent='center' alignItems='center'>
        {attachments.map((attachment, index) => (
          <Box
            key={index}
            display='flex'
            justifyContent='center'
            alignItems='center'
            style={{ maxWidth: '400px', margin: '8px' }}
          >
            <Button
              variant='outlined'
              size='small'
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: '8px',
              }}
              onClick={() => {
                if (attachment?.downloadURL) {
                  fetch(attachment.downloadURL)
                    .then((response) => response.blob())
                    .then((blob) => {
                      const link = document.createElement('a')
                      link.href = URL.createObjectURL(blob)
                      link.download = attachment.name || 'download'
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                      URL.revokeObjectURL(link.href)
                    })
                    .catch((error) => {
                      console.error('Error downloading the file:', error)
                    })
                }
              }}
            >
              {attachment?.name}
            </Button>
          </Box>
        ))}
      </Box>
    </GeneralModal>
  )
}

ClaimAttachmentsButton.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
}

export default ClaimAttachmentsButton
