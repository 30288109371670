import { useState } from 'react'
import Box from '@mui/material/Box'
import PageSection from '@app/components/PageSection'
import Typography from '@mui/material/Typography'
import SelectField from '@app/stories/Select'
import PhoneField from '@app/stories/PhoneInput'
import TextField from '@mui/material/TextField'
import Button from '@app/stories/Button'
import { collection } from '@app/firebase/firestore'

// PageForm moved outside to prevent re-creation on every render
const PageForm = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  companyName,
  setCompanyName,
  role,
  setRole,
  onSubmit,
}) => {
  return (
    <Box
      bgcolor='#fff'
      width='100%'
      height='100%'
      padding='40px'
      border='#ddd 1px solid'
      display='flex'
      flexDirection='column'
      gap={2}
      sx={{
        boxShadow: {
          xs: '16px 16px #652d92',
          md: '24px 24px #652d92',
        },
      }}
    >
      <Box display='flex' gap={2}>
        <Box display='flex' flexDirection='column' width='100%'>
          <Typography mb={1}>First Name</Typography>
          <TextField
            label='Enter first name'
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Box>
        <Box display='flex' flexDirection='column' width='100%'>
          <Typography mb={1}>Last Name</Typography>
          <TextField
            label='Enter last name'
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Box>
      </Box>
      <Box display='flex' gap={2}>
        <Box display='flex' flexDirection='column' width='100%'>
          <Typography mb={1}>Company Email</Typography>
          <TextField
            label='Enter company email'
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box display='flex' flexDirection='column' width='100%'>
          <Typography mb={1}>Phone Number</Typography>
          <PhoneField
            display='noChange'
            margin='none'
            name='phone'
            label='Enter phone number'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='column'>
        <Typography mb={1}>Are you an employer, broker, provider or someone else?</Typography>
        <SelectField
          label='Select an option'
          display='standard'
          options={[
            { value: '', label: 'Select an option' },
            { value: 'employer', label: 'Employer' },
            { value: 'broker', label: 'Broker' },
            { value: 'provider', label: 'Provider' },
            { value: 'other', label: 'Other' },
          ]}
          margin='none'
          value={role}
          onChange={(e) => setRole(e.target.value)}
        />
      </Box>
      <Box display='flex' flexDirection='column'>
        <Typography mb={1}>Company Name</Typography>
        <TextField
          label='Enter company name'
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </Box>
      <Box mt={1}>
        <Button
          variant='contained'
          color='primary'
          style={{
            borderRadius: '32px',
            padding: '16px 32px',
          }}
          onClick={onSubmit}
        >
          Get in touch
        </Button>
      </Box>
    </Box>
  )
}

const GetInTouchSection = ({ customBodyText }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [role, setRole] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async () => {
    if (firstName && lastName && email && phone && companyName && role) {
      const data = {
        firstName,
        lastName,
        email,
        phone,
        company_name: companyName,
        role,
        submitted_at: new Date(),
      }
      await collection('leads').add(data)
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhone('')
      setCompanyName('')
      setRole('')
      setSubmitted(true)
    }
  }

  return (
    <Box id='getInTouch'>
      {/* Get in touch Desktop Section */}
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <PageSection
          height={700}
          direction='up'
          childrenOnSlide
          bgColor='#652d92'
          bgColorSlide='#f4f0f7'
          infoWidth='0%'
        >
          <Box
            display='flex'
            justifyContent='center'
            flexDirection='row'
            height='100%'
            width='100%'
            sx={{
              padding: {
                xs: '16px',
                md: '0px 48px',
              },
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              sx={{
                width: {
                  xs: '100%',
                  md: '55%',
                },
                padding: {
                  xs: '0px',
                  md: '40px',
                },
              }}
            >
              <Typography
                color='#652d92'
                align='left'
                sx={{
                  fontSize: {
                    xs: '2.2rem',
                    sm: '3rem',
                    md: '4rem',
                  },
                  lineHeight: {
                    xs: '1.25',
                  },
                  fontFamily: 'Poppins',
                  fontStyle: 'italic',
                  fontWeight: 500,
                  marginBottom: '-16px',
                }}
              >
                Get in touch
              </Typography>
              <Typography
                color='#652d92'
                align='left'
                sx={{
                  fontSize: {
                    xs: '2.2rem',
                    sm: '3rem',
                    md: '4rem',
                  },
                  lineHeight: {
                    xs: '1.25',
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 300,
                }}
              >
                to learn more.
              </Typography>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: {
                    xs: '1.35rem',
                    sm: '1.5rem',
                    md: '1.75rem',
                  },
                  lineHeight: {
                    xs: '1.2',
                  },
                  marginBottom: {
                    xs: 1,
                    md: 1,
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 300,
                }}
              >
                {customBodyText ||
                  'The end-to-end payer platform for health plans—directly connecting employers and their members with providers and health systems.'}
              </Typography>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              padding='40px'
              sx={{
                width: {
                  xs: '100%',
                  md: '45%',
                },
              }}
            >
              {!submitted ? (
                <PageForm
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  phone={phone}
                  setPhone={setPhone}
                  companyName={companyName}
                  setCompanyName={setCompanyName}
                  role={role}
                  setRole={setRole}
                  onSubmit={onSubmit}
                />
              ) : (
                <Box
                  bgcolor='#fff'
                  width='100%'
                  height='100%'
                  padding='40px'
                  border='#ddd 1px solid'
                  display='flex'
                  flexDirection='column'
                  gap={2}
                  sx={{
                    boxShadow: {
                      xs: '16px 16px #652d92',
                      md: '24px 24px #652d92',
                    },
                  }}
                >
                  <Typography
                    color='#652d92'
                    align='center'
                    sx={{
                      fontSize: {
                        xs: '2.2rem',
                      },
                      lineHeight: {
                        xs: '1.25',
                      },
                      fontFamily: 'Poppins',
                      fontWeight: 300,
                    }}
                  >
                    Thank you for reaching out!
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </PageSection>
      </Box>
      {/* Get in touch Mobile Section */}
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
      >
        <PageSection
          height={1000}
          direction='up'
          childrenOnSlide
          bgColor='#652d92'
          bgColorSlide='#f4f0f7'
          infoWidth='0%'
        >
          <Box
            display='flex'
            justifyContent='center'
            flexDirection='column'
            height='100%'
            width='100%'
            sx={{
              padding: {
                xs: '16px',
                md: '48px',
              },
            }}
          >
            <Box width='100%' display='flex' flexDirection='column'>
              <Typography
                color='#652d92'
                align='center'
                sx={{
                  fontSize: {
                    xs: '2.2rem',
                    sm: '3rem',
                    md: '4rem',
                  },
                  lineHeight: {
                    xs: '1.25',
                  },
                  fontFamily: 'Poppins',
                  fontStyle: 'italic',
                  fontWeight: 500,
                  marginBottom: '-8px',
                }}
              >
                Get in touch
              </Typography>
              <Typography
                color='#652d92'
                align='center'
                sx={{
                  fontSize: {
                    xs: '2.2rem',
                    sm: '3rem',
                    md: '4rem',
                  },
                  lineHeight: {
                    xs: '1.25',
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 300,
                  marginBottom: 2,
                }}
              >
                to learn more.
              </Typography>
              <Typography
                sx={{
                  textAlign: {
                    xs: 'center',
                    lg: 'left',
                  },
                  fontSize: {
                    xs: '1.35rem',
                    sm: '1.5rem',
                    md: '1.75rem',
                  },
                  lineHeight: {
                    xs: '1.2',
                  },
                  marginBottom: {
                    xs: 1,
                    md: 1,
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 300,
                }}
              >
                {customBodyText ||
                  'The end-to-end payer platform for health plans—directly connecting employers and their members with providers and health systems.'}
              </Typography>
            </Box>
            <Box width='100%' display='flex' justifyContent='center' padding='16px'>
              {!submitted ? (
                <PageForm
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  phone={phone}
                  setPhone={setPhone}
                  companyName={companyName}
                  setCompanyName={setCompanyName}
                  role={role}
                  setRole={setRole}
                  onSubmit={onSubmit}
                />
              ) : (
                <Box
                  bgcolor='#fff'
                  width='100%'
                  height='100%'
                  padding='40px'
                  border='#ddd 1px solid'
                  display='flex'
                  flexDirection='column'
                  gap={2}
                  sx={{
                    boxShadow: {
                      xs: '16px 16px #652d92',
                      md: '24px 24px #652d92',
                    },
                  }}
                >
                  <Typography
                    color='#652d92'
                    align='center'
                    sx={{
                      fontSize: {
                        xs: '2.2rem',
                      },
                      lineHeight: {
                        xs: '1.25',
                      },
                      fontFamily: 'Poppins',
                      fontWeight: 300,
                    }}
                  >
                    Thank you for reaching out!
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </PageSection>
      </Box>
    </Box>
  )
}

export default GetInTouchSection
