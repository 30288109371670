import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import { formatDateForUI, formatCurrency } from '@app/libs/formatters'

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  boldText: {
    fontWeight: 'bold',
  },
  redText: {
    color: 'red',
  },
  table: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: '#000',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    padding: 5,
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 8, // Reduced font size for table headers
  },
  tableCell: {
    flex: 1,
    fontSize: 6,
    textAlign: 'center',
    wrap: true, // Ensure text wraps within the cell
    // padding: 2, // Add padding for better readability
  },
  claimTotalsCombinedCell: {
    flex: 2, // Combine the width of the first two cells
    fontSize: 6,
    textAlign: 'center',
    wrap: true,
    // padding: 2,
  },
})

const EOB_PDF = ({ totals, mainPatientInfo, mainProviderInfo, document_num, mainClaim }) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Explanation of Benefits</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.text}>
            Patient's Name:{' '}
            <Text
              style={styles.boldText}
            >{`${mainPatientInfo?.firstname || ''} ${mainPatientInfo?.lastname || ''}`}</Text>
          </Text>
          <Text style={styles.text}>
            Claim Number: <Text style={styles.boldText}>{mainClaim?.code || '---'}</Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>
            Plan Paid:{' '}
            <Text
              style={[styles.boldText, styles.redText]}
            >{`${formatCurrency(totals?.amount_paid || 0)}`}</Text>
          </Text>
          <Text style={styles.text}>
            Claim Processed Date:{' '}
            <Text style={styles.boldText}>{formatDateForUI(mainClaim?.createdAt) || '---'}</Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>
            Patient Paid:{' '}
            <Text
              style={[styles.boldText, styles.redText]}
            >{`${formatCurrency(totals?.patient_paid || 0)}`}</Text>
          </Text>
          <Text style={styles.text}>
            Provider of Services:{' '}
            <Text style={styles.boldText}>{mainProviderInfo?.name || '---'}</Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>
            To: <Text style={styles.boldText}>{mainProviderInfo?.name || '---'}</Text>
          </Text>
          {/* <Text style={styles.text}>
            Place of Service:{' '}
            <Text style={styles.boldText}>{mainClaim?.point_of_service_description || '---'}</Text>
          </Text> */}
        </View>
        <Text style={styles.text}>
          It is not your responsibility to pay:{' '}
          <Text
            style={[styles.boldText, styles.redText]}
          >{`${formatCurrency(totals?.original_charge - totals?.allowed_amount) || 0}`}</Text>
        </Text>
      </View>
      <View style={styles.table}>
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text style={styles.tableCell}>DATE</Text>
          <Text style={styles.tableCell}>TREATMENT</Text>
          <Text style={styles.tableCell}>BILLED</Text>
          <Text style={styles.tableCell}>SAVINGS</Text>
          <Text style={styles.tableCell}>CONTRACTED</Text>
          <Text style={styles.tableCell}>INELIGIBLE</Text>
          <Text style={styles.tableCell}>PLAN PAID</Text>
          <Text style={styles.tableCell}>DEDUC</Text>
          <Text style={styles.tableCell}>COINS</Text>
          <Text style={styles.tableCell}>COPAY</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>
            {formatDateForUI(mainClaim?.service_info?.date_of_service_initial) || '---'}
          </Text>
          <Text style={styles.tableCell}>{mainClaim?.service?.name || '---'}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.allowed_amount || 0)}`}</Text>
          <Text
            style={styles.tableCell}
          >{`${formatCurrency(totals?.original_charge - totals?.allowed_amount)}`}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.allowed_amount || 0)}`}</Text>
          <Text
            style={styles.tableCell}
          >{`${formatCurrency(totals?.ineligible_amount || 0)}`}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.amount_paid || 0)}`}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.deductible || 0)}`}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.coinsurance || 0)}`}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.copay || 0)}`}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.claimTotalsCombinedCell, styles.boldText]}>CLAIM TOTALS</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.allowed_amount || 0)}`}</Text>
          <Text
            style={styles.tableCell}
          >{`${formatCurrency(totals?.original_charge - totals?.allowed_amount)}`}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.allowed_amount || 0)}`}</Text>
          <Text
            style={styles.tableCell}
          >{`${formatCurrency(totals?.ineligible_amount || 0)}`}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.amount_paid || 0)}`}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.deductible || 0)}`}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.coinsurance || 0)}`}</Text>
          <Text style={styles.tableCell}>{`${formatCurrency(totals?.copay || 0)}`}</Text>
        </View>
      </View>
    </Page>
  </Document>
)

export default EOB_PDF
