import { useCurrentUser } from '@app/context/CurrentUser'
import ProfileForm from '@app/components/ProfileForm'
import Grid from '@mui/material/Grid'
import IdInputForm from '@app/components/IdInputForm'
import PreferencesForm from '@app/components/PreferencesForm'
import Heading from '@app/stories/Heading'
import Link from '@app/stories/Link'
import ChangePasswordForm from '@app/containers/ChangePasswordForm'
import Typography from '@mui/material/Typography'

const MyInfo = () => {
  const { profileData, authData } = useCurrentUser()

  return (
    <>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <ProfileForm id={profileData.id} values={profileData} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <PreferencesForm id={profileData.id} values={profileData} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <IdInputForm />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <ChangePasswordForm />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Heading variant='h3'>Request personal information</Heading>

          <Typography>
            To request or delete your personal data, please contact us at{' '}
            <Link type='external' display='inline' to='mailto:help@mishe.co'>
              help@mishe.co
            </Link>{' '}
            or{' '}
            <Link type='external' display='inline' to='tel:+1 (601) 647–4326'>
              1-855-MISHECO
            </Link>{' '}
            (
            <Link type='external' display='inline' size='small' to='tel:+1 (601) 647–4326'>
              1-855-647-4326
            </Link>
            ).
          </Typography>

          <Typography>
            You can also check our{' '}
            <Link
              target='_blank'
              display='inline'
              size='small'
              to={authData.roles.includes('PATIENT') ? '/hipaa' : '/baa'}
            >
              {authData.roles.includes('PATIENT') ? 'HIPAA' : 'BAA'}
            </Link>{' '}
            and{' '}
            <Link
              target='_blank'
              display='inline'
              size='small'
              to={authData.roles.includes('PATIENT') ? '/eula-consumer' : '/eula-provider'}
            >
              EULA
            </Link>{' '}
            agreements.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default MyInfo
