import { useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { retrieveFiretableImageURL } from '@app/libs/formatters'
import frontCard from '@app/assets/images/memberCards/DEFAULT_member_card_front.svg?no-inline'
import backCard from '@app/assets/images/memberCards/DEFAULT_member_card_back.svg?no-inline'
import PatientMemberCardRenderer from '@app/components/PatientDashboard/PlansTab/memberCards/PatientMemberCardRenderer'

const fetchImage = (source, defaultCard) => {
  if (source && source.length) {
    return retrieveFiretableImageURL(source)
  }
  return defaultCard
}

const PatientMemberCards = ({ profile, plan, planID }) => {
  const [frontCardImage, setFrontCardImage] = useState(null)
  const [backCardImage, setBackCardImage] = useState(null)
  const { memberCardSource, memberCardBackSource } = plan.planData
  const { firstname, lastname } = profile || {}

  const planType = useMemo(() => {
    if (!plan) return false
    return plan?.planData.planDesign?.designType?.typeOfPlan || 'medical'
  }, [plan])

  const frontCardTitle = useMemo(() => {
    return `${firstname}_${lastname}_Member_${planType}_Card${Date.now().toString(36).slice(-5)}`
  }, [profile, planType])

  const backCardTitle = useMemo(() => {
    return `${firstname}_${lastname}_MemberBack_${planType}_Card${Date.now().toString(36).slice(-5)}`
  }, [profile, planType])

  useEffect(() => {
    if (planType) {
      const backImage = fetchImage(memberCardBackSource, backCard)
      setBackCardImage(backImage)
    }

    if (planType) {
      const frontImage = fetchImage(memberCardSource, frontCard)
      setFrontCardImage(frontImage)
    }
  }, [plan, planType])

  if (!planType) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Box position='relative' display='inline-block'>
      <Box position='relative' display='inline-block'>
        {frontCardImage && plan && (
          <PatientMemberCardRenderer
            name={`${firstname} ${lastname}`}
            planID={planID}
            plan={plan}
            cardBackground={frontCardImage}
            cardSource={memberCardSource}
            cardTitle={frontCardTitle}
          />
        )}
        {backCardImage && plan && (
          <PatientMemberCardRenderer
            draw={() => {}}
            cardBackground={backCardImage}
            cardTitle={backCardTitle}
            cardSource={memberCardBackSource}
          />
        )}
      </Box>
    </Box>
  )
}

export default PatientMemberCards
