import { useCallback } from 'react'
import { collection } from '@app/firebase/firestore'
import OrderPDFTemplate from '@app/components/PDFTemplates/OrderPDF'
import EOB_PDF from '@app/components/EOB_PDF'

const usePDFs = () => {
  const getClinicLogo = (order) => {
    const clinic = order?.clinic || order?.referringClinic || {}
    if (!clinic?.id) {
      return null
    }
    // make db call to get the organization logo
    return collection('organizations')
      .doc(clinic?.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data()
          // check if .image is an array and if so get downloadURL or return null
          if (data?.image && Array.isArray(data.image)) {
            return data.image[0]?.downloadURL || null
          }
        }
        return null
      })
      .catch((err) => {
        console.log('Error getting organization logo:', err)
        return null
      })
  }

  const createOrderWithLogo = useCallback(async (order) => {
    const clinicLogo = await getClinicLogo(order)

    return <OrderPDFTemplate order={order} clinicLogo={clinicLogo} />
  }, [])

  const createEOBTemplate = useCallback(
    (totals, mainPatientInfo, mainProviderInfo, document_num, mainClaim) => {
      return (
        <EOB_PDF
          totals={totals}
          mainPatientInfo={mainPatientInfo}
          mainProviderInfo={mainProviderInfo}
          document_num={document_num}
          mainClaim={mainClaim}
        />
      )
    },
    [],
  )

  return {
    createOrderWithLogo,
    createEOBTemplate,
  }
}

export default usePDFs
