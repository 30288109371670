function deep_value(obj, path) {
  const value = path.split('.').reduce((o, key) => (o ? o[key] : undefined), obj)
  return value === 0 ? 0 : value || ''
}
export const PLAN_UTIL_VERSION = Date.now()

function resolveValue(data, item) {
  return item.value.replace(/{{(.*?)}}/g, (_, g) => deep_value(data, g.trim()) ?? '')
}

const drawElem = (ctx, text, x, y, fill = 'white', fontWeight = 'normal', fontSize = 24) => {
  ctx.font = `${fontWeight} ${fontSize}px Arial`
  ctx.fillStyle = fill
  ctx.fillText(text, x, y)
}

export const drawCoordsCard = (ctx, data, coords) => {
  if (!data.plan || !coords) return

  const { width } = data

  const fontSize = width * 0.01
  const maxW = 420
  const lineHeight = fontSize * 2.4
  coords.forEach(({ x, y, color, ...valueDef }) => {
    const resolved = resolveValue(data, valueDef)
    wrapText(ctx, `${resolved}`, x, y, maxW, lineHeight, color || 'white')
  })
}

function wrapText(ctx, text, x, y, maxW, lineHeight, color = 'white') {
  const boldRegex = /<b>(.*?)<\/b>/g
  let words = text.split(/(<b>.*?<\/b>|\s+)/).filter((p) => p.trim() !== '')

  let currentLine = []
  let currentLineWidth = 0

  function drawCurrentLine() {
    let drawX = x
    for (let part of currentLine) {
      let isBold = part.match(boldRegex)
      let textContent = part.replace(/<\/?b>/g, '')
      let fontWeight = isBold ? 'bold' : 'normal'

      ctx.font = `${fontWeight} 22px Arial`
      let wordWidth = ctx.measureText(textContent).width

      drawElem(ctx, textContent, drawX, y, color, fontWeight, 22)
      drawX += wordWidth + 5
    }
    y += lineHeight
    currentLine = []
    currentLineWidth = 0
  }

  for (let word of words) {
    let isBold = word.match(boldRegex)
    let textContent = word.replace(/<\/?b>/g, '')
    let fontWeight = isBold ? 'bold' : 'normal'

    ctx.font = `${fontWeight} 22px Arial`
    let wordWidth = ctx.measureText(textContent).width

    if (currentLineWidth + wordWidth > maxW) {
      drawCurrentLine()
    }

    currentLine.push(word)
    currentLineWidth += wordWidth + 5
  }

  if (currentLine.length > 0) {
    drawCurrentLine()
  }

  return y
}
