import { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@app/stories/TextField'
import Typography from '@app/stories/Typography'
import Button from '@app/stories/Button'
import { useCurrentUser } from '@app/context/CurrentUser'
import DateRangeIcon from '@mui/icons-material/DateRange'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { DatePicker } from '@mui/x-date-pickers'

import useIdentificationCards from '@app/hooks/useIdentificationCards'

import styled from '@mui/system/styled'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const IdInputForm = () => {
  const { profileData } = useCurrentUser()
  const [disabled, setDisabled] = useState(false)

  const [errorTimer, setErrorTimer] = useState(null)
  const [loadError, setLoadError] = useState(null)

  const [validIdInfo, setValidIdInfo] = useState(false)
  const [idNumber, setIdNumber] = useState(null)
  const [idExpiry, setIdExpiry] = useState(null)
  const [idImage, setIdImage] = useState(null)
  const [preview, setPreview] = useState(null)

  const [idErrorMessage, setIdErrorMessage] = useState(null)
  const [displayIdForm, setDisplayIdForm] = useState(false)
  const [displaySuccess, setDisplaySuccess] = useState(false)

  const { checkProfileForID, checkForIdentificationInfo, updateProfileWithIdentificationInfo } =
    useIdentificationCards()

  useEffect(() => {
    console.log('no return')
    if (loadError) {
      clearTimeout(errorTimer)
    }
    setErrorTimer(
      setTimeout(() => {
        setLoadError(true)
      }, 10000),
    )
  }, [])

  useEffect(() => {
    if (profileData) {
      setValidIdInfo(checkProfileForID({ profile: profileData, setIdErrorMessage }))
      console.log('  -- setting id info')
      console.log(profileData?.idInfo)
      console.log(profileData?.idInfo?.idLink)
      setPreview(profileData?.idInfo?.idLink)
    }
  }, [profileData])

  useEffect(() => {
    if (!idImage) {
      setPreview(null)
      return
    }
    setPreview(URL.createObjectURL(idImage))
  }, [idImage])

  const handleIdInfo = useCallback(
    async (id) => {
      setIdErrorMessage(null)

      console.log('  -- uploading id info')
      console.log('=======================IdImage')
      console.log(idImage)

      if (
        !checkForIdentificationInfo({
          idNumber,
          idExpiry,
          idImageFile: idImage,
          errorCallback: setIdErrorMessage,
        })
      ) {
        console.log('  ** id info not valid')
        setDisabled(false)
        return
      }

      const idUploadLink = await updateProfileWithIdentificationInfo({
        id,
        idNumber,
        idExpiry,
        idFile: idImage,
      })

      console.log('  -- id upload result')
      console.log(idUploadLink ? 'success' : 'failure')
      if (idUploadLink) {
        setDisplayIdForm(false)
        setDisabled(true)
      }

      if (!idUploadLink) {
        console.log('  ** error uploading id info')
        setIdErrorMessage(
          'Oh uh, looks like we ran into an issue uploading your ID info.\nPlease contact support if this issue persists.',
        )
        setDisabled(false)
        return
      }

      return idUploadLink
    },
    [idNumber, idExpiry, idImage],
  )

  return (
    <>
      <Typography variant='h3' marginBottom='16px'>
        Government ID
      </Typography>
      <Box>
        {validIdInfo && !displayIdForm && (
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: '30px 0px' }}>
            <Typography variant='h5' align='center'>
              {displaySuccess
                ? 'Your ID was uploaded successfully.'
                : 'You already have a valid ID on file. But can update it if needed.'}
            </Typography>
            {profileData?.idInfo?.idLink && (
              <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                <img
                  id='id_preview'
                  src={profileData?.idInfo?.idLink}
                  alt='ID Preview'
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    height: 'auto',
                    border: '3px solid #652d92',
                    borderRadius: '8px',
                  }}
                />
              </Box>
            )}
            {!displaySuccess && (
              <Box display='flex' alignItems='center' justifyContent='center' mt={2}>
                <Button
                  variant='contained'
                  onClick={() => {
                    setDisplayIdForm(true)
                  }}
                >
                  <Typography variant='body1'>Update ID</Typography>
                </Button>
              </Box>
            )}
          </Grid>
        )}
        {displayIdForm || !validIdInfo ? (
          <Box marginBottom='32px'>
            {idErrorMessage && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  variant='body1'
                  style={{
                    color: 'red',
                  }}
                >
                  {idErrorMessage}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box>
                <Typography variant='caption'>
                  Enter your ID/Drivers License Number as it appears.
                </Typography>
              </Box>
              <Box>
                <TextField
                  id='dirx-id-number'
                  value={idNumber || ''}
                  onChange={(e) => {
                    setIdNumber(e.target.value)
                  }}
                  label='ID/Drivers License Number'
                  variant='outlined'
                  fullWidth
                  disabled={disabled && true}
                  sx={{
                    marginTop: '0px',
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box>
                <Typography variant='caption'>
                  ID must expire more than 7 days from today.
                </Typography>
              </Box>
              <Box>
                <DatePicker
                  id='id-expiry'
                  label='ID/Drivers License Expiration Date'
                  value={idExpiry}
                  onChange={setIdExpiry}
                  inputVariant='outlined'
                  bgcolor='background.paper'
                  format='MM-dd-yyyy'
                  keyboardIcon={<DateRangeIcon />}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  slotProps={{ textField: { required: true, fullWidth: true } }}
                  disabled={disabled && true}
                  minDate={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '10px' }} mb={2}>
              <Box mt={1} width='100%' display='flex' alignItems='center' justifyContent='center'>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  flexDirection='column'
                  width='100%'
                >
                  <Button
                    component='label'
                    variant='outlined'
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    disabled={disabled && true}
                    size='large'
                    sx={{
                      minWidth: '155px',
                    }}
                  >
                    <Typography variant='body1' whiteSpace='nowrap'>
                      Upload ID
                    </Typography>
                    <VisuallyHiddenInput
                      id='dirx-id-file'
                      type='file'
                      accept='image/*'
                      disabled={disabled && true}
                      onChange={(e) => {
                        setIdImage(e.target.files[0])
                        const objectUrl = URL.createObjectURL(e.target.files[0])
                        setPreview(objectUrl)
                      }}
                    />
                  </Button>
                  <Button
                    component='label'
                    variant='outlined'
                    tabIndex={-1}
                    startIcon={<DeleteForeverIcon />}
                    disabled={disabled && true}
                    size='large'
                    sx={{
                      marginTop: '10px',
                      minWidth: '155px',
                    }}
                    onClick={() => {
                      setIdImage(null)
                    }}
                  >
                    <Typography variant='body1' whiteSpace='nowrap'>
                      Clear
                    </Typography>
                  </Button>
                </Box>
                <Box
                  width='100%'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  padding={2}
                >
                  {preview ? (
                    <img
                      id='id_preview'
                      src={preview}
                      alt='ID Preview'
                      style={{
                        width: '100%',
                        maxWidth: '400px',
                        height: 'auto',
                      }}
                    />
                  ) : (
                    <Typography variant='body1'>
                      Please upload a clear picture of the front of your ID.
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
            <Box display='flex' alignItems='center' justifyContent='center' mt={2}>
              <Button
                variant='contained'
                disabled={disabled}
                onClick={async () => {
                  setDisabled(true)
                  const idLink = await handleIdInfo(profileData.id)
                  if (idLink) {
                    setDisabled(false)
                    setDisplaySuccess(true)
                  }
                }}
              >
                Submit ID Upload
              </Button>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </>
  )
}

export default IdInputForm
